<template>
    <div class="section">
        <div
            v-if="skinnyContainerLg"
            class="container-lg"
        >
            <div class="row">
                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 pt-sm-6">
                    <slot />
                </div>
            </div>
        </div>

        <slot v-else />
    </div>
</template>

<script>
    /**
     * Use for all text input fields. Has label, validation, placeholder and filters.

     * @prop{Boolean} skinny-container-lg (optional) Wraps the content within a skinny container on LG screens
     */

    export default {
        name: 'SectionBlock',
        props: {
            skinnyContainerLg: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/sectionBlock';
</style>
