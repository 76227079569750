<template>
    <marketing-layout>
        <education-article
            :title="$t('education.articleTitles.helocOnRentalProperties')"
            :html-content="$t('education.content.helocOnRentalProperties')"
            image-src="pages/education/helocOnRentalPropertiesArticle.jpg"
            :publish-date="new Date('10/14/2021')"
            :read-length-in-minutes="4"
        />
    </marketing-layout>
</template>

<script>
    import EducationArticle from '@/layouts/EducationArticle'
    import MarketingLayout from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import { educationPagePaths } from '@/routes/educationRoutes'

    export default {
        name: 'HelocOnRentalProperties',
        metaInfo: {
            title: String(i18n.t('education.articleTitles.helocOnRentalProperties')),
            meta: [
                { name: 'title', content: String(i18n.t('education.articleTitles.helocOnRentalProperties')) },
                { name: 'description', vmid: 'description', content: String(i18n.t('education.articleDescriptions.helocOnRentalProperties')) },
                { name: 'og:title', content: String(i18n.t('education.articleTitles.helocOnRentalProperties')) },
                { name: 'og:description', vmid: 'og:description', content: String(i18n.t('education.articleDescriptions.helocOnRentalProperties')) },
                { name: 'og:image', vmid: 'og:image', content: require('../../assets/images/pages/education/helocOnRentalPropertiesArticle.jpg') },
            ],
        },
        components: {
            EducationArticle,
            MarketingLayout,
        },
        data() {
            return {
                educationPagePaths,
            }
        },
        mounted: function () {
            this.$logEvent('view_heloc_on_rental_properties')
        },
    }
</script>
