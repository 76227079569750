<template>
    <marketing-layout :has-landing-footer="true">
        <marketing-hero>
            <h1 class="fw-bold heading-3 text-responsive mb-3 w-100 text-start text-md-center">
                {{ $t('pages.education.howItWorksTitle') }}
            </h1>
            <h2 class="fw-light heading-5 text-responsive mb-0 text-start text-md-center">
                {{ $t('pages.education.howItWorksSubtitle') }}
            </h2>
        </marketing-hero>
        <div class="container mb-5">
            <div class="row">
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/whatIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Credit Card Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.whatTitle') }}
                    </div>
                    <div v-html="$t('pages.education.whatSubtitle')" />
                </div>
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/howIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="House Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.howTitle') }}
                    </div>
                    <div v-html="$t('pages.education.howSubtitle')" />
                </div>
            </div>
            <div class="row">
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/rateIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Percent Sign Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.rateTitle') }}
                    </div>
                    <div v-html="$t('pages.education.rateSubtitle')" />
                </div>
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/paymentIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Calculator Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.paymentTitle') }}
                    </div>
                    <div v-html="$t('pages.education.paymentSubtitle')" />
                </div>
            </div>
            <div class="row">
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/cashIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Cash Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.cashTitle') }}
                    </div>
                    <div v-html="$t('pages.education.cashSubtitle')" />
                </div>
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/creditScoreIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Meter Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.creditScoreTitle') }}
                    </div>
                    <div v-html="$t('pages.education.creditScoreSubtitle')" />
                </div>
            </div>
            <div class="row">
                <div class="mb-5 col-md-6">
                    <img
                        src="@/assets/images/pages/marketing/education/feesIcon.svg"
                        width="32"
                        height="32"
                        class="mb-2"
                        alt="Price Tag Icon"
                    >
                    <div class="fw-bold">
                        {{ $t('pages.education.feesTitle') }}
                    </div>
                    <div v-html="$t('pages.education.feesSubtitle')" />
                </div>
            </div>
        </div>
        <section-block>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6">
                        <h4 class="text-responsive text-center">
                            More Questions...
                        </h4>
                        <form-field-search-support :inline-article="true" />
                    </div>
                </div>
            </div>
        </section-block>
        <section-block class="bg-light">
            <div class="container">
                <div class="col-md-8 mx-auto">
                    <h1 class="fw-bold heading-3 text-responsive mb-3 w-100 text-start text-md-center">
                        {{ $t('pages.education.title') }}
                    </h1>
                    <h2 class="fw-light heading-5 text-responsive mb-0 text-start text-md-center pb-7 pb-md-9">
                        {{ $t('pages.education.subtitle') }}
                    </h2>
                </div>
                <div class="row row-cols-1 row-cols-md-2 gy-3 gy-md-5 pb-5">
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.homeEquityLineOfCreditHelocCardWhatIsIt')"
                            :description="truncate($t('education.content.homeEquityLineOfCreditHelocCardWhatIsIt'))"
                            image-src="pages/education/homeEquityLineOfCreditHelocCardWhatIsItCard.jpg"
                            :education-page-path="educationPagePaths.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.homeEquityLineOfCreditHelocCardHowItWorks')"
                            :description="truncate($t('education.content.homeEquityLineOfCreditHelocCardHowItWorks'))"
                            image-src="pages/education/homeEquityLineOfCreditHelocCardHowItWorksCard.jpg"
                            :education-page-path="educationPagePaths.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_HOW_IT_WORKS"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.homeEquityCreditCardHowToGetOne')"
                            :description="truncate($t('education.content.homeEquityCreditCardHowToGetOne'))"
                            image-src="pages/education/homeEquityCreditCardHowToGetOneCard.jpg"
                            :education-page-path="educationPagePaths.HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.theFastestWayToGetAHeloc')"
                            :description="truncate($t('education.content.theFastestWayToGetAHeloc'))"
                            image-src="pages/education/theFastestWayToGetAHelocCard.jpg"
                            :education-page-path="educationPagePaths.THE_FASTEST_WAY_TO_GET_A_HELOC"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.whenAreHelocsHomeEquityLinesOfCreditAGoodIdea')"
                            :description="truncate($t('education.content.whenAreHelocsHomeEquityLinesOfCreditAGoodIdea'))"
                            image-src="pages/education/whenAreHelocsHomeEquityLinesOfCreditAGoodIdea.jpg"
                            :education-page-path="educationPagePaths.WHEN_ARE_HELOCS_HOME_EQUITY_LINES_OF_CREDIT_A_GOOD_IDEA"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.whatIsAHomeEquityLineOfCreditHelocABeginnersGuide')"
                            :description="truncate($t('education.content.whatIsAHomeEquityLineOfCreditHelocABeginnersGuide'))"
                            image-src="pages/education/whatIsAHomeEquityLineOfCreditHelocABeginnersGuide.jpg"
                            :education-page-path="educationPagePaths.WHAT_IS_A_HOME_EQUITY_LINE_OF_CREDIT_HELOC_A_BEGINNERS_GUIDE"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences')"
                            :description="truncate($t('education.content.homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences'))"
                            image-src="pages/education/homeEquityLinesCreditHelocsVsMortgagesSimilaritiesDifferences.jpg"
                            :education-page-path="educationPagePaths.HOME_EQUITY_LINES_CREDIT_HELOCS_VS_MORTGAGES_SIMILARITIES_DIFFERENCES"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.howAreHelocRatesDetermined')"
                            :description="truncate($t('education.content.howAreHelocRatesDetermined'))"
                            image-src="pages/education/howAreHelocRatesDeterminedCard.jpg"
                            :education-page-path="educationPagePaths.HOW_ARE_HELOC_RATES_DETERMINED"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.refinancingAHeloc')"
                            :description="truncate($t('education.content.refinancingAHeloc'))"
                            image-src="pages/education/refinancingAHelocCard.jpg"
                            :education-page-path="educationPagePaths.REFINANCING_A_HELOC"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.howToGetLowestRate')"
                            :description="truncate($t('education.content.howToGetLowestRate'))"
                            image-src="pages/education/howToGetLowestRateCard.jpg"
                            :education-page-path="educationPagePaths.HOW_TO_GET_LOWEST_RATE"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.fixedOrVariable')"
                            :description="truncate($t('education.content.fixedOrVariable'))"
                            image-src="pages/education/fixedOrVariableCard.jpg"
                            :education-page-path="educationPagePaths.FIXED_OR_VARIABLE"
                        />
                    </div>
                    <div class="col">
                        <education-card
                            :title="$t('education.articleTitles.helocOnRentalProperties')"
                            :description="truncate($t('education.content.helocOnRentalProperties'))"
                            image-src="pages/education/helocOnRentalPropertiesCard.jpg"
                            :education-page-path="educationPagePaths.HELOC_ON_RENTAL_PROPERTIES"
                        />
                    </div>
                </div>
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import EducationCard from '@/components/EducationCard'
    import MarketingLayout from '@/layouts/Marketing'
    import { educationPagePaths } from '@/routes/educationRoutes'
    import MarketingHero from '@/components/MarketingHero'
    import SectionBlock from '../../components/SectionBlock'
    import FormFieldSearchSupport from '@/components/base/FormFieldSearchSupport'

    export default {
        name: 'Education',
        metaInfo: {
            title: 'Education',
            meta: [
                {
                    name: 'description',
                    vmid: 'description',
                    content: 'Free educational content by experts to help you make smarter financial decisions about products like HELOCs, Credit Cards and more.',
                },
            ],
        },
        components: {
            FormFieldSearchSupport,
            SectionBlock,
            MarketingHero,
            EducationCard,
            MarketingLayout,
        },
        data() {
            return {
                educationPagePaths,
            }
        },
        methods: {
            truncate(string) {
                // return full words, not cut-off words
                const subStrArr = string.substr(0, 102).split(' ')
                const strWithoutLastWord = subStrArr.splice(0, subStrArr.length - 1).join(' ')
                return `${strWithoutLastWord}...`
            },
        },
        mounted: function () {
            this.$logEvent('view_education')
        },
    }
</script>
