<template>
    <marketing-layout>
        <education-article
            :title="$t('education.articleTitles.howToGetLowestRate')"
            :html-content="$t('education.content.howToGetLowestRate')"
            image-src="pages/education/howToGetLowestRateArticle.jpg"
            :publish-date="new Date('09/07/2021')"
            :read-length-in-minutes="5"
        />
    </marketing-layout>
</template>

<script>
    import EducationArticle from '@/layouts/EducationArticle'
    import MarketingLayout from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import { educationPagePaths } from '@/routes/educationRoutes'

    export default {
        name: 'HowToGetLowestRate',
        metaInfo: {
            title: String(i18n.t('education.articleTitles.howToGetLowestRate')),
            meta: [
                { name: 'title', content: String(i18n.t('education.articleTitles.howToGetLowestRate')) },
                { name: 'description', vmid: 'description', content: String(i18n.t('education.articleDescriptions.howToGetLowestRate')) },
                { name: 'og:title', content: String(i18n.t('education.articleTitles.howToGetLowestRate')) },
                { name: 'og:description', vmid: 'og:description', content: String(i18n.t('education.articleDescriptions.howToGetLowestRate')) },
                { name: 'og:image', vmid: 'og:image', content: require('../../assets/images/pages/education/howToGetLowestRateArticle.jpg') },
            ],
        },
        components: {
            EducationArticle,
            MarketingLayout,
        },
        data() {
            return {
                educationPagePaths,
            }
        },
        mounted: function () {
            this.$logEvent('view_how_to_get_lowest_rate')
        },
    }
</script>
