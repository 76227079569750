<template>
    <router-link
        class="education-card"
        :to="educationPagePath"
    >
        <img
            :src="getImage"
            :alt="title"
        >
        <div class="content-container">
            <h5>
                {{ title }}
            </h5>
            <p
                class="text-gray-500"
                v-html="description"
            />
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'EducationCard',
        props: {
            title: { type: String, required: true },
            description: { type: String, required: true },
            imageSrc: { type: String, required: true },
            educationPagePath: { type: String, required: true },
        },
        computed: {
            getImage() {
                return require(`../assets/images/${this.imageSrc}`)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/educationCard';
</style>
