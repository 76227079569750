<template>
    <marketing-layout>
        <education-article
            :title="$t('education.articleTitles.homeEquityLineOfCreditHelocCardHowItWorks')"
            :html-content="$t('education.content.homeEquityLineOfCreditHelocCardHowItWorks')"
            image-src="pages/education/homeEquityLineOfCreditHelocCardHowItWorksArticle.jpg"
            :publish-date="new Date('05/21/2021')"
            :read-length-in-minutes="5"
        />
        <div class="container">
            <h3 class="fw-light text-center mb-7">
                Related Articles
            </h3>
            <div class="row row-cols-1 row-cols-sm-2 gy-3 gy-md-5 mb-5">
                <div class="col">
                    <education-card
                        :title="$t('education.articleTitles.homeEquityLineOfCreditHelocCardWhatIsIt')"
                        :description="truncate($t('education.content.homeEquityLineOfCreditHelocCardWhatIsIt'))"
                        image-src="pages/education/homeEquityLineOfCreditHelocCardWhatIsItCard.jpg"
                        :education-page-path="educationPagePaths.HOME_EQUITY_LINE_OF_CREDIT_HELOC_CARD_WHAT_IS_IT"
                    />
                </div>
                <div class="col">
                    <education-card
                        :title="$t('education.articleTitles.homeEquityCreditCardHowToGetOne')"
                        :description="truncate($t('education.content.homeEquityCreditCardHowToGetOne'))"
                        image-src="pages/education/homeEquityCreditCardHowToGetOneCard.jpg"
                        :education-page-path="educationPagePaths.HOME_EQUITY_CREDIT_CARD_HOW_TO_GET_ONE"
                    />
                </div>
                <div class="col">
                    <education-card
                        :title="$t('education.articleTitles.theFastestWayToGetAHeloc')"
                        :description="truncate($t('education.content.theFastestWayToGetAHeloc'))"
                        image-src="pages/education/theFastestWayToGetAHelocCard.jpg"
                        :education-page-path="educationPagePaths.THE_FASTEST_WAY_TO_GET_A_HELOC"
                    />
                </div>
            </div>
        </div>
    </marketing-layout>
</template>

<script>
    import EducationArticle from '@/layouts/EducationArticle'
    import EducationCard from '@/components/EducationCard'
    import MarketingLayout from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import { educationPagePaths } from '@/routes/educationRoutes'

    export default {
        name: 'HomeEquityLineOfCreditHelocCardHowItWorks',
        metaInfo: {
            title: String(i18n.t('education.articleTitles.homeEquityLineOfCreditHelocCardHowItWorks')),
            meta: [
                { name: 'title', content: String(i18n.t('education.articleTitles.homeEquityLineOfCreditHelocCardHowItWorks')) },
                { name: 'description', vmid: 'description', content: String(i18n.t('education.articleDescriptions.homeEquityLineOfCreditHelocCardHowItWorks')) },
            ],
        },
        components: {
            EducationArticle,
            EducationCard,
            MarketingLayout,
        },
        data() {
            return {
                educationPagePaths,
            }
        },
        mounted: function () {
            this.$logEvent('view_home_equity_line_of_credit_heloc_card_how_it_works')
        },
        methods: {
            truncate(string) {
                return `${string.substring(0, 102)}...`
            },
        },
    }
</script>
