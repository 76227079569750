<template>
    <div class="marketing-hero container-fluid">
        <div class="container-lg">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2 d-flex flex-column align-items-center text-center">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MarketingHero',
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/marketingHero';
</style>
