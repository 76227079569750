<template>
    <div class="education-article">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <h1 class="heading-3 text-responsive text-center mb-7">
                        {{ title }}
                    </h1>
                </div>
            </div>
        </div>
        <div class="container image-container">
            <img
                :src="getImage"
                :alt="title"
            >
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8 offset-md-2">
                    <h6 class="section-header my-7">
                        Published {{ prettyDate }} | {{ readLengthInMinutes }} min read
                    </h6>
                    <div v-html="htmlContent" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateTime from 'luxon/src/datetime'

    export default {
        name: 'EducationArticle',
        props: {
            title: {
                type: String,
                required: true,
            },
            htmlContent: {
                type: String,
                required: true,
            },
            imageSrc: {
                type: String,
                required: true,
            },
            publishDate: { type: Date, required: true },
            readLengthInMinutes: { type: Number, required: true },
        },
        computed: {
            getImage() {
                return require(`../assets/images/${this.imageSrc}`)
            },
            prettyDate() {
                return DateTime.fromJSDate(this.publishDate).toFormat('DD')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/pages/educationArticle';
</style>
